


















































import { Component, Vue } from 'vue-property-decorator';
import { readOrigemLocalidadeLista } from '@/store/origem_localidade/getters';
import { dispatchDeleteOrigemLocalidade, dispatchGetOrigemLocalidadeLista } from '@/store/origem_localidade/actions';
import { IOrigemLocalidade } from '@/interfaces/origem_localidade';
import ModalConfirmacao from '@/components/ModalConfirmacao.vue';

@Component({
  components: {
    ModalConfirmacao,
  },
})
export default class ListarOrigemLocalidade extends Vue {
  public modalExclusaoAtivo: boolean = false;
  public origemLocalidadeExclusao;

  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'center',
    },
    {
      text: 'Nome',
      sortable: true,
      value: 'nome',
      align: 'left',
    },
    {
      text: 'Sigla',
      sortable: true,
      value: 'sigla',
      align: 'left',
    },
    {
      text: 'Ações',
      align: 'center',
    },
  ];

  public options = {
    rowsPerPage: 10,
  };

  get origemLocalidadeLista() {
    return readOrigemLocalidadeLista(this.$store);
  }

  public abrirModalExclusao(origemLocalidade: IOrigemLocalidade) {
    this.origemLocalidadeExclusao = origemLocalidade;
    this.modalExclusaoAtivo = true;
  }

  public async confirmarExclusao() {
    const excluiu = await dispatchDeleteOrigemLocalidade(this.$store, this.origemLocalidadeExclusao);
    await dispatchGetOrigemLocalidadeLista(this.$store);
    this.modalExclusaoAtivo = false;
  }

  public async mounted() {
    await dispatchGetOrigemLocalidadeLista(this.$store);
  }
}
